import { Typography } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";

import { ROLE_IDS } from "../../seeds/data/role";

import Loading from "components/Loading";
import DashboardLayout from "components/dashboard/DashboardLayout";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";

import { STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME } from "types/api";

const Home: React.FC = () => {
  const router = useRouter();
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.isInitialized && auth.roles.length > 0) {
      // Handle Incident Management only plan. No menu items except incident management
      if (
        auth.subscription &&
        auth.subscription !== "loading" &&
        auth.subscription.items.find(
          (item) => item.product.name === STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME
        )
      ) {
        router.push(ROUTES.INCIDENT_MANAGEMENT);
        return;
      }

      if (auth.roles.length === 1) {
        switch (auth.roles[0].role_id) {
          case ROLE_IDS[0]:
            router.push(ROUTES.ADMIN_DASHBOARD);
            break;
          case ROLE_IDS[1]:
            router.push(ROUTES.COACH_DASHBOARD);
            break;
          case ROLE_IDS[5]:
            router.push(ROUTES.CLIENTS);
            break;
          default:
            router.push(ROUTES.DASHBOARD);
        }
      } else {
        router.push(ROUTES.DASHBOARD);
      }
    }
  }, [router, auth]);

  return (
    <DashboardLayout title="Root">
      {auth.isInitialized && auth.roles.length > 0 ? (
        <Typography>
          You do not have permission to view your dashboard. Please contact an
          administrator to resolve this issue.
        </Typography>
      ) : (
        <Loading open />
      )}
    </DashboardLayout>
  );
};

export default Home;
